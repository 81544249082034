import React, { useState, useRef, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import {
  AVAILABLE_PERMISSIONS,
  checkIfCurrentUserHasPermission,
  PK_TYPENAMES,
  SEARCH_CUSTOMERS_QUERY,
  useCreateThread,
  useHasSmsChannel,
  hasSMSBlocked,
} from 'client-lib';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import uniqid from 'uniqid';
import i18n from 'i18n-js';
import {
  isAttachmentFileTypeSupported,
  ALLOWED_FILENAME_REGEX,
  NOT_ALLOWED_FILENAME_REGEX,
  fileNameWithoutExtension,
  fileExtension,
  contactName,
  formatPhoneNumber,
} from 'client-lib/src/lib/utils/helpers';
import autosize from 'autosize';
import {
  showUploadProgressBar,
  hideUploadProgressBar,
  showUploadFailureBar,
  setDefaultContact,
} from '../../actions/uploadModal';
import { phoneRegex, normalizePhone } from '../Inputs/validation';
import { closeCreateSectionModal, openSnackbar } from '../../actions/general';
import {
  setCreateThread,
  clearCreateSection,
} from '../../actions/createSection';
import UploadArt from '../Modals/UploadArt';
import {
  Heading2,
  Button,
  TextArea,
  Select,
  SelectedEntity,
  InfiniteSuggest,
  InputError,
  Chip,
  Text,
  Avatar,
} from '../../elements';
import CreateMultiTag from '../Tag/CreateMultiTag';
import isSMSNotCompatible from '../../utils/helpers/isSMSNotCompatible.ts';
import THEMES from '../../styles/themes/library/button';
import APP_THEMES from '../../styles/themes/app';
import { CreateFormAttachment } from '../FileUpload/CreateFormAttachment';
import AnnotationsModal from '../Annotations/AnnotationsModal';
import AddPhoneNumber from '../Modals/AddPhoneNumber';
import CreateSectionButtons from './CreateSectionButtons.tsx';
import useGetUserAccountPolicies from '../../hooks/customer/useGetUserAccountPolicies';

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 50px;
  min-height: 90%;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  max-width: 600px;
  align-items: stretch;
  justify-content: ${(props) =>
    props.centerVertically ? 'center' : 'flex-start'};
  margin-bottom: 12px;
`;

const FullFormRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 32px;
`;

const HeadingWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 48px;
  column-gap: 12px;
`;

const modalBodyStyleWithDragging = {
  height: '100%',
  width: '100%',
};

const addRecipientsCustomStyles = (props) => `
  color: ${THEMES.LINK_TEXT(props)};
  font-weight: bold;
  padding: 0;
  :hover {
    color: ${THEMES.LINK_TEXT_HOVER(props)};
  }
`;

const AdditionalRecipientsChipsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
`;

const ChipContainer = styled.div`
  margin: 8px 8px 0 0;
`;

const CreateThread = ({ createSection }) => {
  const client = useApolloClient();

  const history = useHistory();

  const [toFieldError, setToFieldError] = useState('');

  const [annotate, setAnnotate] = useState(false);

  const [emojiMenuOpen, setEmojiMenuOpen] = useState(false);

  const [contactText, setContactText] = useState('');

  const [isDraggingOnPage, setIsDraggingOnPage] = useState(false);

  const [addPhoneToggle, setAddPhoneToggle] = useState(false);

  const [pasteMeta, setPasteMeta] = useState({
    cursorPosition: 0,
    pasted: false,
    event: null,
  });

  const [templateModalOpen, setTemplateModalOpen] = useState(false);

  const [isGroupMessage, setIsGroupMessage] = useState(false);

  const [newRecipientText, setNewRecipientText] = useState('');

  const [currentSenderGroupHasBandwidth, setCurrentSenderGroupHasBandwidth] =
    useState('');

  const messageInput = useRef();

  const dispatch = useDispatch();
  const threadsActiveGroupIds = useSelector(
    (state) => state?.session?.threadsActiveGroupIds
  );
  const currentUser = useSelector((state) => state?.session?.currentUser);
  const uploadModal = useSelector((state) => state?.uploadModal);
  const allGroups = useSelector((state) => state?.accountData?.allGroups);
  const presenceTrackingAvailable = useSelector(
    (state) => state?.accountData?.account?.ff_presence_tracking
  );
  const accountPolicies = currentUser?.accountPolicies;
  const groupPolicies = currentUser?.groupPolicies;

  const { hasSmsChannel } = useHasSmsChannel({
    currentUser,
    threadsActiveGroupIds,
    client,
  });

  useGetUserAccountPolicies({
    actionList: [AVAILABLE_PERMISSIONS.GROUP_TEXT],
    userId: currentUser?.userId,
  });

  const hasGroupTextPermissions = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.GROUP_TEXT,
    accountPolicies,
    groupPolicies
  );

  const onMutationStart = () => {
    dispatch(showUploadProgressBar());
    dispatch(closeCreateSectionModal());
  };

  const onMutationSuccess = (data) => {
    const startedThread = data.startThread.thread;

    // TODO conditional based on if the thread owner is the user here?
    // if (startedThread.ownerContact.id === currentContactId) ...
    history.push(`/threads/open/${startedThread.id}`);

    dispatch(clearCreateSection());
    dispatch(hideUploadProgressBar());
  };

  const onMutationError = (err) => {
    setAttachment(null);
    dispatch(showUploadFailureBar());
    setTimeout(() => dispatch(hideUploadProgressBar()), 3000);
    console.error(err);
  };

  const handleNotifyError = (msg) => {
    dispatch(openSnackbar(msg, 'error'));
  };

  const {
    handleMutationStartThread,
    contact,
    setContact,
    contactPhoneNumber,
    setContactPhoneNumber,
    senderGroup,
    setSenderGroup,
    groupOptions,
    tags,
    setTags,
    messageInputValue,
    setMessageInputValue,
    attachment,
    setAttachment,
    sendAttachmentAsLink,
    setSendAttachmentAsLink,
    additionalRecipients,
    setAdditionalRecipients,
    groupOptionsWithBandwidth,
  } = useCreateThread({
    client,
    currentUser,
    threadsActiveGroupIds,
    onMutationStart,
    onMutationSuccess,
    onMutationError,
    initialThread: createSection.createThread,
    onError: handleNotifyError,
    allGroups,
  });

  useEffect(() => {
    if (senderGroup?.value) {
      const renderGroupMessage = groupOptionsWithBandwidth.some(
        (option) => option.value === senderGroup.value
      );
      setCurrentSenderGroupHasBandwidth(renderGroupMessage);
    }
  }, [senderGroup?.value]);

  useEffect(() => {
    if (!currentSenderGroupHasBandwidth) {
      setIsGroupMessage(false);
      setAdditionalRecipients([]);
    }
  }, [currentSenderGroupHasBandwidth]);

  useEffect(() => {
    if (uploadModal.defaultContact) {
      const { defaultContact } = uploadModal;
      setContact(defaultContact);
      setContactPhoneNumber(defaultContact.phoneNumber);
    }

    if (messageInput?.current) {
      autosize(messageInput.current);
    }

    return () => dispatch(setDefaultContact(null));
  }, []);

  useEffect(() => {
    if (messageInput?.current) {
      autosize.update(messageInput.current);
    }
  }, [messageInputValue]);

  const showFromField = threadsActiveGroupIds?.length > 1;

  const focusOnTextArea = () => messageInput?.current?.focus?.();

  useEffect(() => {
    if (contactPhoneNumber) {
      focusOnTextArea();
    }
  }, [contactPhoneNumber]);

  const checkIsSuggestionAlreadyIncluded = (phoneNumber) => {
    return (
      additionalRecipients?.some(
        (recipient) =>
          normalizePhone(recipient?.phoneNumber) === normalizePhone(phoneNumber)
      ) || normalizePhone(phoneNumber) === contactPhoneNumber
    );
  };

  const checkIsSuggestionAlreadyMainContact = (suggestion) =>
    suggestion === contactPhoneNumber;

  const onSuggestionSelected = (e, { suggestion }) => {
    // First set state based on selection, then move focus to messageInput.
    const isSuggestionAlreadyIncluded = checkIsSuggestionAlreadyIncluded(
      suggestion.phoneNumber
    );
    if (isSuggestionAlreadyIncluded) {
      setContactText('');
      setToFieldError(i18n.t('slideouts-CreateThread-alreadyIncluded'));
    } else if (suggestion?.disabled) {
      setContactPhoneNumber('');
      setContact(null);
      setContactText('');
      setToFieldError(i18n.t('slideouts-CreateThread-optedOutError'));
    } else if (!suggestion?.phoneNumber) {
      setContactPhoneNumber(suggestion.phoneNumber);
      setContact(suggestion);
      setToFieldError('');
      setAddPhoneToggle(true);
    } else {
      setContactPhoneNumber(suggestion.phoneNumber);
      setContact(suggestion);
      setToFieldError('');
    }
  };

  const onChange = (e) => setContactText(e.target.value);

  const onNewRecipientFieldChange = (e) => setNewRecipientText(e.target.value);

  const handleInputChange = (e) => setMessageInputValue(e.target.value);

  const onAdditionalRecipientSuggestionSelected = (e, { suggestion }) => {
    if (suggestion.phoneNumber) {
      const isSuggestionAlreadyIncluded = checkIsSuggestionAlreadyIncluded(
        suggestion.phoneNumber
      );
      const isMainContact = checkIsSuggestionAlreadyMainContact(
        suggestion.phoneNumber
      );
      setNewRecipientText('');

      if (isSuggestionAlreadyIncluded || isMainContact)
        dispatch(
          openSnackbar(
            i18n.t('slideouts-CreateThread-duplicateContacted', {
              defaultValue: 'Duplicate contact entered',
            }),
            'error'
          )
        );
      if (
        !isSuggestionAlreadyIncluded &&
        !isMainContact &&
        additionalRecipients?.length <= 8
      ) {
        setAdditionalRecipients([...additionalRecipients, suggestion]);
      }
    } else {
      setNewRecipientText('');
    }
  };

  const onAdditionalRecipientDeleted = (deletedContact) => {
    const newAdditionalRecipients = additionalRecipients.filter(
      (contact) => contact.phoneNumber !== deletedContact.phoneNumber
    );
    setAdditionalRecipients(newAdditionalRecipients);
  };

  const certifyAdditionalRecipientsInputValueOnBlur = (e) => {
    const trimmedValue = e.target.value.trim();
    const isAlreadyMainContact =
      checkIsSuggestionAlreadyMainContact(trimmedValue);
    const isAlreadyInAdditionalRecipients =
      checkIsSuggestionAlreadyIncluded(trimmedValue);
    setNewRecipientText('');
    if (isAlreadyMainContact || isAlreadyInAdditionalRecipients)
      dispatch(
        openSnackbar(
          i18n.t('slideouts-CreateThread-duplicateContact', {
            defaultValue: 'Duplicate contact entered',
          }),
          'error'
        )
      );
    if (
      phoneRegex.test(trimmedValue) === true &&
      !isAlreadyMainContact &&
      !isAlreadyInAdditionalRecipients &&
      additionalRecipients.length <= 8
    ) {
      const number = normalizePhone(trimmedValue);
      setAdditionalRecipients([
        ...additionalRecipients,
        { phoneNumber: number },
      ]);
    }
  };

  const certifyToInputValueOnBlur = (e) => {
    const trimmedValue = e.target.value.trim();
    const isSuggestionAlreadyIncluded =
      checkIsSuggestionAlreadyIncluded(trimmedValue);
    if (phoneRegex.test(trimmedValue) === true) {
      if (isSuggestionAlreadyIncluded) {
        setContactText('');
        setToFieldError(i18n.t('slideouts-CreateThread-alreadyIncluded'));
      } else {
        const number = normalizePhone(trimmedValue);
        setContactPhoneNumber(number);
        setContactText(number);
      }
    } else if (!contact || contactPhoneNumber === '') {
      setContactText('');
    }
  };

  const handleEnterSubmit = (e) => {
    if (e.shiftKey && e.key === 'Enter') {
      e.preventDefault();
      if (contactPhoneNumber) {
        handleMutationStartThread();
      }
    }
  };

  const handleSetAttachment = ({
    originalFile,
    originalFilename = originalFile.name,
  }) => {
    const reader = new window.FileReader();
    reader.onload = () => {
      const attachment = {
        data: reader.result,
        originalFilename,
        type: originalFile.type,
        id: uniqid(),
        size: originalFile.size,
      };
      setAttachment(attachment);
    };
    reader.readAsDataURL(originalFile);
  };

  const handleOnPaste = (e) => {
    e.preventDefault();
    e.persist();

    let clipBoardItem = null;

    if (e.clipboardData.types.includes('Files')) {
      clipBoardItem = e.clipboardData.items[1]
        ? e.clipboardData.items[1]
        : e.clipboardData.items[0];
    } else {
      clipBoardItem = e.clipboardData.items[0];
      if (
        e.clipboardData.types[0] === 'text/plain' ||
        e.clipboardData.types[0] === 'text/html'
      ) {
        // Paste in text based on cursor location.
        const pastedText = e.clipboardData.getData('Text');
        const selectionStart = e.target.selectionStart; // cursor location in input
        const selectionEnd = e.target.selectionEnd;
        const beforeSelection = e.target.value.slice(0, selectionStart);
        const afterSelection = e.target.value.slice(selectionEnd);
        const cursorPosition = beforeSelection.length + pastedText.length;

        setMessageInputValue(
          `${beforeSelection}${pastedText}${afterSelection}`
        );
        setPasteMeta({ cursorPosition, pasted: true, event: e });

        return;
      }
    }

    const originalFile = clipBoardItem.getAsFile();
    handleSetAttachment({ originalFile });
  };

  useEffect(() => {
    if (pasteMeta.pasted && pasteMeta.event) {
      const { event, cursorPosition } = pasteMeta;
      event.target.setSelectionRange(cursorPosition, cursorPosition);
      setPasteMeta({ pasted: false, event: null, cursorPosition: 0 });
    }
  }, [pasteMeta.pasted]);

  useEffect(() => {
    const createThreadRedux = {
      contact,
      contactPhoneNumber,
      messageInputValue,
      attachment: attachment || null,
      senderGroup,
      additionalRecipients,
    };
    dispatch(setCreateThread(createThreadRedux));
  }, [
    contact?.id,
    contactPhoneNumber,
    messageInputValue,
    attachment?.id,
    senderGroup?.value,
  ]);

  const clearToFieldBubble = () => {
    setContactText('');
    setContactPhoneNumber('');
    setContact(null);
  };

  const validateAttachment = (originalFile) => {
    const filename = fileNameWithoutExtension(originalFile.name);
    const extension = `.${fileExtension(originalFile.name)}`;
    if (!isAttachmentFileTypeSupported(originalFile.name)) {
      dispatch(
        openSnackbar(i18n.t('slideouts-CreateThread-unsupportedFile'), 'error')
      );
    } else if (originalFile.size > 5000000) {
      dispatch(
        openSnackbar(i18n.t('slideouts-CreateThread-fileTooLarge'), 'error')
      );
    } else if (!filename.match(ALLOWED_FILENAME_REGEX)) {
      const removedSpecialCharacters = filename.replace(
        NOT_ALLOWED_FILENAME_REGEX,
        ''
      );
      const adaptedFilename = `${removedSpecialCharacters}${extension}`;
      handleSetAttachment({ originalFile, originalFilename: adaptedFilename });
    } else {
      handleSetAttachment({ originalFile });
    }
  };

  const handleFileReaderInputChange = (e) => {
    const originalFile = e.target.files[0];
    validateAttachment(originalFile);
    e.target.value = null;
  };

  const handleOnDragEnter = (e) => {
    e.preventDefault();
    setIsDraggingOnPage(true);
  };

  const handleOnDragLeave = (e) => {
    e.preventDefault();
    setIsDraggingOnPage(false);
  };

  const handleOnDrop = (acceptedFiles) => {
    setIsDraggingOnPage(false);
    validateAttachment(acceptedFiles[0]);
  };

  const handlePhoneToggle = (newState) => {
    setAddPhoneToggle(newState);
  };

  const handleEmojiSelection = (emoji) => {
    const selectionStart = messageInput.current.selectionStart; // cursor group in input
    const beforeSelection = messageInput.current.value.slice(0, selectionStart);
    const afterSelection = messageInput.current.value.slice(selectionStart);
    setMessageInputValue(`${beforeSelection}${emoji.native} ${afterSelection}`);
    setEmojiMenuOpen(false);

    setTimeout(() => messageInput?.current?.focus?.(), 0);
  };

  const renderAttachmentSection = () =>
    attachment ? (
      <FullFormRow>
        <CreateFormAttachment
          attachments={[attachment]}
          onClose={() => setAttachment(null)}
          sendAsLink={sendAttachmentAsLink}
          onMethodChange={() => setSendAttachmentAsLink(!sendAttachmentAsLink)}
          allowAnnotation
          onAnnotate={() => setAnnotate(true)}
        />
      </FullFormRow>
    ) : null;

  const handleCloseAnnotation = (uri) => {
    setAnnotate(false);
    handleSaveAnnotation(uri);
  };

  const handleSaveAnnotation = (uri) => {
    const newAttachment = {
      data: uri,
      originalFilename: attachment.originalFilename,
      type: attachment.type,
      // id: attachment.id,
      id: uniqid(), // creating a new id on annotation save, so that the image for sure re-renders when new state set.
      size: attachment.size,
    };
    setAttachment(newAttachment);
  };

  const inferAvatarChildren = (contact) => {
    if (contact?.__typename === PK_TYPENAMES.CUSTOMER_CONTACT) {
      return contact?.firstName && contact?.lastName
        ? contactName(contact)
        : undefined;
    }

    return undefined;
  };

  const inferEntityCardMainText = (contact) => {
    if (contact?.__typename === PK_TYPENAMES.CUSTOMER_CONTACT) {
      return contact?.firstName && contact?.lastName
        ? contactName(contact)
        : contactPhoneNumber;
    }
    if (!contact && contactPhoneNumber) {
      return contactPhoneNumber;
    }

    return '';
  };

  const inferEntityCardSubText = (contact) => {
    if (contact?.__typename === PK_TYPENAMES.CUSTOMER_CONTACT) {
      return contact?.account?.name || '';
    }

    return '';
  };

  let isContact = false;

  if (contact?.__typename === PK_TYPENAMES.CUSTOMER_CONTACT) {
    isContact = true;
  }

  const sharedValidation =
    (isContact ? contactPhoneNumber : contact?.id || contactPhoneNumber) &&
    (showFromField ? senderGroup?.value : true);
  const enableSubmissionWithMessage = sharedValidation && messageInputValue;
  const enableSubmissionWithAttachment =
    sharedValidation &&
    attachment &&
    fileNameWithoutExtension(attachment.originalFilename);

  const checkTemplateDisabled = !contactPhoneNumber || !senderGroup;

  const handleTemplateButtonClick = () => {
    if (!checkTemplateDisabled) {
      setTemplateModalOpen(!templateModalOpen);
    }
  };

  const getRightAlignedText = (suggestionNode, isAdditionalRecipient) => {
    if (hasSMSBlocked(suggestionNode)) {
      return i18n.t('threads-CreateThread-blocked', {
        defaultValue: 'Blocked',
      });
    }
    if (suggestionNode?.conversationsOptOut) {
      return i18n.t('slideouts-GroupMessageRecipients-optedOut');
    }
    if (!suggestionNode?.phoneNumber) {
      return i18n.t(
        isAdditionalRecipient
          ? 'modals-AddPhoneNumber-noPhone'
          : 'modals-AddPhoneNumber-addPhone',
        {
          defaultValue: isAdditionalRecipient
            ? 'No Phone Number'
            : 'Add Phone Number',
        }
      );
    }
    if (isSMSNotCompatible(suggestionNode.smsStatus)) {
      return i18n.t('sidebar-CreateSms-notCompatible', {
        defaultValue: 'Not text compatible',
      });
    }
    return formatPhoneNumber(suggestionNode.phoneNumber);
  };

  return (
    <Dropzone
      disableClick
      multiple={false}
      onDragEnter={handleOnDragEnter}
      onDragLeave={handleOnDragLeave}
      onDrop={handleOnDrop}
      style={modalBodyStyleWithDragging}
    >
      <Container>
        <InnerContainer centerVertically={isDraggingOnPage}>
          {isDraggingOnPage ? (
            <UploadArt height="60%" />
          ) : (
            <>
              <HeadingWrap>
                <Avatar size="lg" type="external" icon="smsChannel" />
                <Heading2 dataTestId="new-message-header">
                  {i18n.t('threads-NewMessage-header')}
                </Heading2>
              </HeadingWrap>
              {!hasSmsChannel && (
                <InputError
                  error={i18n.t('slideouts-CreateMessage-notEnabled', {
                    defaultValue: 'This channel is not enabled for this group',
                  })}
                />
              )}
              <Select
                label={i18n.t('slideouts-CreateThread-fromLabel')}
                options={groupOptions}
                value={senderGroup}
                onChange={(option) => setSenderGroup(option)}
                disabled={!hasSmsChannel || !showFromField}
              />
              {contactPhoneNumber === '' && !contact?.id ? (
                <InfiniteSuggest
                  inputProps={{
                    label: i18n.t('slideouts-CreateThread-toLabel'),
                    error: toFieldError,
                    onBlur: certifyToInputValueOnBlur,
                    value: contactText,
                    onChange,
                    autoFocus: true,
                    disabled: !hasSmsChannel,
                    placeholder: i18n.t(
                      'slideouts-CreateThread-enterNameOrNumber'
                    ),
                    rightSideLabelContent:
                      !isGroupMessage &&
                      currentSenderGroupHasBandwidth &&
                      hasGroupTextPermissions ? (
                        <Button
                          onClick={() => setIsGroupMessage(true)}
                          customStyle={addRecipientsCustomStyles}
                          size="zero"
                          type="noStyle"
                          dataTestId="add-recipients-btn"
                        >
                          {i18n.t('slideouts-CreateThread-addRecipients')}
                        </Button>
                      ) : null,
                  }}
                  query={SEARCH_CUSTOMERS_QUERY}
                  queryKey="searchCustomers"
                  queryVariables={{
                    query: contactText,
                    after: null,
                    typeFilter: 'CUSTOMER_CONTACT',
                  }}
                  onSelect={onSuggestionSelected}
                  includePresence={presenceTrackingAvailable}
                  includeGroupIndicator
                  isDisabled={(node) =>
                    node?.conversationsOptOut ||
                    hasSMSBlocked(node) ||
                    isSMSNotCompatible(node.smsStatus)
                  }
                  rightAlignedText={(node) => getRightAlignedText(node, false)}
                />
              ) : (
                <div style={{ position: 'relative' }}>
                  {!isGroupMessage &&
                  currentSenderGroupHasBandwidth &&
                  hasGroupTextPermissions ? (
                    <Button
                      size="zero"
                      type="noStyle"
                      dataTestId="add-recipients-btn"
                      onClick={() => setIsGroupMessage(true)}
                      customStyle={(props) =>
                        `font-weight: bold;position: absolute; top: 8px; right: 0; color: ${THEMES.LINK_TEXT(
                          props
                        )};  
                        :hover {
                          color: ${THEMES.LINK_TEXT_HOVER(props)};
                        };`
                      }
                    >
                      {i18n.t('slideouts-CreateThread-addRecipients')}
                    </Button>
                  ) : null}
                  <SelectedEntity
                    label={i18n.t('slideouts-CreateThread-toLabel')}
                    avatarChildren={inferAvatarChildren(contact)}
                    maintext={inferEntityCardMainText(contact)}
                    subtext={inferEntityCardSubText(contact)}
                    onDelete={clearToFieldBubble}
                    customDeleteButtonProps={{
                      dataTestId: 'selected-entity-delete-btn',
                    }}
                    entityCardDataTestId="selected-sms-contact"
                  />
                </div>
              )}
              {isGroupMessage &&
              currentSenderGroupHasBandwidth &&
              hasGroupTextPermissions ? (
                <InfiniteSuggest
                  inputProps={{
                    label: i18n.t('slideouts-CreateThread-additional', {
                      defaultValue: 'Additional Recipients',
                    }),
                    onBlur: certifyAdditionalRecipientsInputValueOnBlur,
                    value: newRecipientText,
                    onChange: onNewRecipientFieldChange,
                    disabled: additionalRecipients?.length === 9,
                    autoFocus: true,
                    placeholder: i18n.t(
                      'slideouts-CreateThread-enterNameOrNumber'
                    ),
                    hideBottomSpace: additionalRecipients?.length >= 1,
                    rightSideLabelContent: additionalRecipients?.length >=
                      0 && (
                      <Text
                        customStyle={(props) =>
                          `font-size: 12px; color: ${
                            additionalRecipients?.length === 9
                              ? APP_THEMES.THEME_RED(props)
                              : APP_THEMES.FOREGROUND_HIGH(props)
                          }`
                        }
                      >
                        {additionalRecipients?.length}
                        /9
                      </Text>
                    ),
                  }}
                  query={SEARCH_CUSTOMERS_QUERY}
                  queryKey="searchCustomers"
                  queryVariables={{
                    query: newRecipientText,
                    after: null,
                    typeFilter: 'CUSTOMER_CONTACT',
                  }}
                  onSelect={onAdditionalRecipientSuggestionSelected}
                  includeGroupIndicator
                  isDisabled={(node) =>
                    node?.conversationsOptOut ||
                    hasSMSBlocked(node) ||
                    !node?.phoneNumber ||
                    isSMSNotCompatible(node.smsStatus)
                  }
                  rightAlignedText={(node) => getRightAlignedText(node, true)}
                  displayAddPhoneNumberButton={false}
                />
              ) : null}
              {additionalRecipients?.length >= 1 && (
                <AdditionalRecipientsChipsWrapper>
                  {additionalRecipients.map((contact, i) => {
                    const { phoneNumber } = contact;
                    const name = contactName(contact);
                    return (
                      <ChipContainer key={i}>
                        <Chip
                          onDelete={() => onAdditionalRecipientDeleted(contact)}
                          customMaintextStyle={() =>
                            'max-width: 150px; text-overflow: ellipsis; overflow: hidden;'
                          }
                        >
                          {name || phoneNumber}
                        </Chip>
                      </ChipContainer>
                    );
                  })}
                </AdditionalRecipientsChipsWrapper>
              )}
              {contact && (
                <AddPhoneNumber
                  open={addPhoneToggle}
                  contact={contact}
                  onClose={() => {
                    handlePhoneToggle(false);
                    clearToFieldBubble();
                  }}
                  onSave={(numb) => {
                    setContactPhoneNumber(numb);
                    handlePhoneToggle(false);
                  }}
                  onError={() => {}}
                />
              )}
              <TextArea
                label={i18n.t('slideouts-CreateThread-message')}
                rightSideLabelContent={
                  hasSmsChannel && (
                    <CreateMultiTag tags={tags} setTags={setTags} />
                  )
                }
                placeholder={i18n.t('slideouts-CreateThread-pressShift')}
                onKeyPress={handleEnterSubmit}
                value={messageInputValue}
                onChange={handleInputChange}
                onPaste={handleOnPaste}
                ref={messageInput}
                id="textarea"
                name="messageInputValue"
                rows={8}
                disabled={!hasSmsChannel}
                dataTestId="at-textarea"
                customLabelWrapperStyle={() => `
                  align-items: flex-end;
                  max-height: 38px;
                `}
                customTextAreaStyle={() => `
                  max-height: calc(100vh - 115px);
                `}
              />
              {renderAttachmentSection()}
              <CreateSectionButtons
                attachment={attachment}
                enableSubmissionWithAttachment={
                  !!enableSubmissionWithAttachment
                }
                enableSubmissionWithMessage={!!enableSubmissionWithMessage}
                handleMutationStartThread={handleMutationStartThread}
                checkTemplateDisabled={checkTemplateDisabled}
                hasSmsChannel={hasSmsChannel}
                handleTemplateButtonClick={handleTemplateButtonClick}
                handleFileReaderInputChange={handleFileReaderInputChange}
                handleEmojiSelection={handleEmojiSelection}
                emojiMenuOpen={emojiMenuOpen}
                setEmojiMenuOpen={setEmojiMenuOpen}
                setMessageInputValue={setMessageInputValue}
                setAttachment={setAttachment}
                templateModalOpen={templateModalOpen}
                multiple={false}
                setTemplateModalOpen={setTemplateModalOpen}
                emailSignature={false}
                contactId={contact?.id}
                senderGroupId={senderGroup?.value}
                channelType="SMS"
              />
            </>
          )}
        </InnerContainer>
        <AnnotationsModal
          open={annotate}
          setOpen={setAnnotate}
          attachment={attachment}
          handleSave={handleCloseAnnotation}
        />
      </Container>
    </Dropzone>
  );
};

CreateThread.propTypes = {
  createSection: PropTypes.object.isRequired,
};

export default CreateThread;
