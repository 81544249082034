import { useQuery } from "@apollo/client";
import EMAIL_SIGNATURE_QUERY from "../../../graphql/query/EmailSignatureQuery.graphql";

/**
 * Custom hook to manage email signatures.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {Object} params.client - The Apollo Client instance.
 * @param {string} params.userId - The ID of the user to check for email signature settings.
 * @param {string} params.messageVal - The current message where the email signature will be appended.
 * @param {Function} params.setMessageVal - Function to update the messageVal state.
 * @param {Object} params.richTextEditorRef - The reference to the RichTextEditor component.
 * @param {Function} params.setHTMLVal - (optional) Function to update the HTML value state.
 *
 * @returns {Object} - The hook's return values.
 * @returns {boolean} emailSigOn - Indicates if the user's email signature is enabled.
 * @returns {boolean} autoAddSig - Indicates if the email signature should be automatically added.
 * @returns {Function} appendEmailSignature - Function to append the email signature to a given value.
 * @returns {Function} handleAddSignature - Function to handle adding the email signature, with an optional callback for additional UI actions.
 */
const useEmailSignature = ({
  client,
  userId,
  messageVal,
  setMessageVal,
  richTextEditorRef,
  setHTMLVal,
}) => {
  const { data } = useQuery(EMAIL_SIGNATURE_QUERY, {
    client,
    variables: {
      userId,
    },
  });
  const signature = data?.emailSignature?.emailSignature?.body;

  const appendEmailSignature = (value) => `${value}\n\n${signature}`;

  // Callback used to set cursor, close bubble, or any other UI
  const handleAddSignature = (onAddSigCb) => {
    if (richTextEditorRef?.current && setHTMLVal) {
      setHTMLVal(richTextEditorRef.current, signature);
    }
    setMessageVal(appendEmailSignature(messageVal));
    onAddSigCb?.();
  };

  return {
    emailSigOn: data?.emailSignature?.emailSigOn,
    autoAddSig: data?.emailSignature?.autoAddSig,
    appendEmailSignature,
    handleAddSignature,
  };
};

export default useEmailSignature;
