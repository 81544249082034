/**
 * Enum representing the types of insert operations that can be performed in the Rich Text Editor.
 *
 * @enum {number}
 * @property {number} insertAtSelection - Represents an insert operation that inserts content at the current selection.
 * @property {number} replaceNodes - Represents an insert operation that replaces all the content in the editor.
 */
// eslint-disable-next-line import/prefer-default-export
export enum InsertType {
  appendNodes,
  insertAtSelection,
  replaceNodes,
}
